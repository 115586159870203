import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface ClientModal {
  clientCode: string
  clientName: string
  email: string
  firstName: string
  lastName: string
}

export const initialstateClientModal: ClientModal = {
  clientCode: '',
  clientName: '',
  email: '',
  firstName: '',
  lastName: '',
}

export interface DashboardState {
  documentId: number
  documentName: string
  client: ClientModal
}

const initialState: DashboardState = {
  documentId: 0,
  documentName: 'nimbusDocument',
  client: initialstateClientModal,
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboard: (state) => {
      state.documentId = initialState.documentId
      state.documentName = initialState.documentName
    },
  },
})

// actions will export in here
export const { resetDashboard } = dashboardSlice.actions

export const selectDocumentId = (state: RootState) => state.dashboard.documentId
export const selectDocumentName = (state: RootState) => state.dashboard.documentName
export const selectModalFields = (state: RootState) => state.dashboard.client

export default dashboardSlice.reducer
